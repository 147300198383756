import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Img from 'gatsby-image'

import '../styles/templates/ServicesPage.scss'

// Components
import {
  TitleDefault,
  TitleSubDefault,
  ContentDefault,
  ButtonDefault
} from 'components/Elements'
import ParseContent from 'components/ParseContent'
import Layout from 'components/Layout'
import SEO from 'components/SEO'

// Images
import arrow from 'img/arrow-right.svg'


export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        header {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                fixed(width: 800, toFormat: JPG) {
                    src
                }
              }
            }
          }
        }

        content_left {
          text_left
          image_right {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 850){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        content_right {
          image_left {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 850){
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          text_right
        }

        usps {
          title
          usp {
            item
          }
        }

      }
      yoast_meta {
        name
        content
        property
      }
    }

    footerItems: wordpressWpComponents(wordpress_id: { eq: 25 }) {
        wordpress_id
        acf {
          subfooter_title
          subfooter_text
          subfooter_button_text
          subfooter_button_location
      }
    }
  }
`

function landingsPage({ 
  data: { page, footerItems },
  pageContext: {
    breadcrumb: { crumbs },
  },
  }) {
  return (
    <Layout>
      {page.acf.header.image && page.acf.header.image.localFile ? (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} image={page.acf.header.image.localFile.childImageSharp.fixed.src} article />
      ) : (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} />
      )}
      <div className="services-template">
        <section className="heroimage position-relative d-flex">
          <div className="container context">
            <div className="row py-4">
              <div className="col-lg-6">
                <TitleDefault className="mb-3 color-text-light">
                  {page.title}
                </TitleDefault>
                <div className="line mb-4" />
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=">"
                  crumbLabel={page.title}
                />
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
          <div className="heroimage-image row position-absolute">
            <div className="col-12 col-lg-5 fill" />
            <div className="heroimage-image-container col-lg-7 p-0">
              <BackgroundImage
                className="bgimage"
                fluid={
                  page.acf.header.image.localFile
                    .childImageSharp.fluid
                }
              />
            </div>
          </div>
        </section>
        <section className="service-content">
          <div className="container">
            <div className="row pt-5">
              {page.acf.usps.usp.map(({ item }, index) => (
                <>
                  <div key={index} className="col usp-list-item justify-content-center">
                    <img src={arrow} width="7" alt="" />
                    <span>{item}</span>
                  </div>
                </>
                ))}
              </div>
              {page.acf.content_left && (
                <div className="row py-5 align-items-center">
                  <div className="col-lg-7">
                    <ContentDefault>
                      <ParseContent content={page.acf.content_left.text_left} />
                    </ContentDefault>
                  </div>
                  {page.acf.content_left.image_right && (
                    <div className="col-lg-5 pr-lg-0">
                      <Img fluid={page.acf.content_left.image_right.localFile.childImageSharp.fluid} />
                    </div>
                  )}
                </div>
              )}
              </div>
          </section>

          <section className="py-5">
            <div className="subfooter color-background-contrast py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <TitleSubDefault className="mb-3">
                      {footerItems.acf.subfooter_title}
                    </TitleSubDefault>
                    <ContentDefault>
                      <div
                        className="color-text-light"
                        dangerouslySetInnerHTML={{
                          __html: footerItems.acf.subfooter_text,
                        }}
                      />
                    </ContentDefault>
                  </div>
                  <div className="col-lg-1 d-none d-lg-block" />
                  <div className="col-lg-4">
                    <ButtonDefault to={footerItems.acf.subfooter_button_location}>
                      {footerItems.acf.subfooter_button_text}
                    </ButtonDefault>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              {page.acf.content_right && (
                <div className="row py-5 align-items-center">
                  <div className="col-lg-5 pl-lg-0">
                    <Img fluid={page.acf.content_right.image_left.localFile.childImageSharp.fluid} />
                  </div>
                  <div className="col-lg-7 pl-lg-5 pr-lg-0">
                    <ContentDefault>
                      <ParseContent content={page.acf.content_right.text_right} />
                    </ContentDefault>
                  </div>
                </div>
              )}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default landingsPage
